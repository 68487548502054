import React, {useEffect, useState} from 'react';
import {validateAndUpdateState} from "../../services/validation";
import {Shout} from '../../services/shout';

import './style.scss';
import Input from '../Input';

const AddressItem = (props) => {
    useEffect(() => {
        shout.subscribe("goToSigningButtonOneClicked", (data) => {
            setGoToSigningButtonOneClicked(true);
        });
        return () => {
            shout.unsubscribe("goToSigningButtonOneClicked");
        }

    }, [])

    const [goToSigningButtonOneClicked, setGoToSigningButtonOneClicked] = useState(false);

    const {
        addressObjectName,
        setCustomer,
        customer,
        validation,
        setValidation
    } = props;

    let shout = new Shout();

    let address = customer[addressObjectName];
    const onAddressPropertyChanged = (addressObjectName, value, fieldName) => {
        validateAndUpdateState(setValidation, validation, fieldName, value);
        let c = {...customer};
        c[addressObjectName][fieldName] = value; //like customer["deliveryAddress"]["street_address"] = "Bastugatan"
        setCustomer(c);
    }

    return (
        <div className="address-item col-container">
            <div className="col three full_line">
                <Input id={`${addressObjectName}_company_name_input`} errorMessage={validation.company_name.isValid === false ? 'Fyll i giltigt företagsnamn' : ''}
                       onChange={(e) => onAddressPropertyChanged(addressObjectName, e.target.value, "company_name")}
                       label="Företagsnamn"
                       placeholder="Företagsnamn"
                       disabled={props.disabled}
                       className={validation.company_name.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked ? 'hideError' : ''}`}
                       value={address.company_name}/>
            </div>
            <div className="col two full_line">
                <Input id={`${addressObjectName}_street_address_input`} errorMessage={validation.street_address.isValid === false ? 'Fyll i giltig adress' : ''}
                       onChange={(e) => onAddressPropertyChanged(addressObjectName, e.target.value, "street_address")}
                       label="Adress"
                       placeholder="Adress"
                       disabled={props.disabled}
                       className={validation.street_address.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked ? 'hideError' : ''}`}
                       value={address.street_address}/>
            </div>
            <div className="col two">
                <div className="col-container">
                    <div className="col three full_line">
                        <Input id={`${addressObjectName}_postal_code_input`} errorMessage={validation.postal_code.isValid === false ? 'Fyll i giltigt postnummer, 5 siffror' : ''}
                               onChange={(e) => onAddressPropertyChanged(addressObjectName, e.target.value, "postal_code")}
                               label="Postnummer"
                               placeholder="Postnummer"
                               disabled={props.disabled}
                               className={validation.postal_code.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked ? 'hideError' : ''}`}
                               value={address.postal_code}/>
                    </div>
                    <div className="col three full_line">
                        <Input id={`${addressObjectName}_city_input`} errorMessage={validation.city.isValid === false ? 'Fyll i giltig ort' : ''}
                               onChange={(e) => onAddressPropertyChanged(addressObjectName, e.target.value, "city")}
                               label="Ort"
                               placeholder="Ort"
                               disabled={props.disabled}
                               className={validation.city.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked ? 'hideError' : ''}`}
                               value={address.city}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddressItem;
