
import React from 'react';

const CompanyOwnership = (props) => {
  
    let formValidation =  props.signatoryIsValid;
    if(props.agreement === undefined || props.agreement === null){
        return null;
    }
  return formValidation
        ? <React.Fragment>
        {
        props.agreement.has_beneficiaries &&
            <div className="company-ownership">
                <div>
                    <h2>
                        Uppgifter om verklig huvudman
                    </h2>
                    <h5>
                        Nedanstående uppgifter har hämtats från Bolagsverkets register över verkliga huvudmän. Om uppgifterna inte stämmer ber vi er registrera korrekta uppgifter på Bolagsverket.se.
                        Genom att signera avtalet bekräftar du att uppgifterna är korrekta.
                    </h5>
                </div>
                <h4 className="beneficiary-owner-title">{(props.agreement.stated_beneficiaries || []).length > 1 ? "Verkliga huvudmän" : "Verklig huvudman"}</h4>
                {( props.agreement.stated_beneficiaries || []).map((principal, index) =>
                    <div key={index} className="col-container">
                        <div className="col three">
                            {principal.name}
                        </div>
                        <div className="col full_line">
                            {principal.personal_identity_number}
                        </div>
                    </div>
                )}
            </div>
            }
        </React.Fragment>
        : null;
}
export default CompanyOwnership;



