import React from 'react';
import "../css/bannerStyle.scss";
import "./styles.scss";
import ButtonWithLeftArrow from "../../components/ButtonWithLeftArrow";
import postMessage from "../../services/postMessage";

const RejectedBanner = (props) => {
    const isEmbedded = window.self === window.top;
    const changePayment=(e)=>{
        e.preventDefault();
        postMessage.checkoutCancel(props.checkoutModel.order_references);
    }
    const reset = () => {
        props.onReset();
    }
    
    return (
            <div id="rejected-banner" className="banner_container">
                <h4 className="header">Tyvärr kan vi inte erbjuda ert företag det här betalalternativet</h4>
                <div className="content"> 
                    <p className="info-text">
                        <br/> Vänligen välj ett annat betalalternativ.
                    </p>
                    <div>
                        <ButtonWithLeftArrow
                            id="change-payment-method"
                            onClick={e => changePayment(e)}
                            className="change-payment-method__button"
                            label={`Byt betalsätt`} />
                    </div>
                    <div>
                        <ButtonWithLeftArrow
                            id="change-organization-number"
                            onClick={e => reset(e)}
                            className="change-orgno-method__button"
                            label={`Byt organisationsnummer`} />
                    </div>
                    
                    <div className="clear-both">
                    </div>
                </div>
            </div>
    )
};
export default RejectedBanner;



