import React, {useState, useEffect} from 'react';
import {
    CleanIdentityNumber,
    GetIdentityNumberCleanedAs10Digits,
    isOrganizationNumberValid
} from "../../services/validation";
import {Shout} from '../../services/shout';
import Input from '../Input';
import emailValidator from "email-validator";
import {putCustomer} from "../../services/api";
import Spinner from "../../components/Spinner";
//import {customerMock_rejected} from "./customerMock";
import "./style.scss";

const OrganizationNumberLookup = (props) => {
    const [orgNrIsValid, setOrgNrIsValid] = useState(false);
    const [organizationNumber, setOrganizationNumber] = useState("");
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(null);
    const [purchaserEmail, setPurchaserEmail] = useState("");
    const [customerNotFoundError, setCustomerNotFoundError] = useState(false);

    let shout = new Shout();

    useEffect(() => {
        emailChanged(props.checkoutModel.purchaser_email);

        let setOrgNo = props.checkoutModel.customer_organization_number;
        if (setOrgNo === null) {
            setOrgNo = "";
        }

        validateOrgNumber(setOrgNo);
        shout.subscribe("activateTestOrgnumber", (data) => {
            setOrganizationNumber(data.testOrgNumber);
            setOrgNrIsValid(true);
        });
        return () => {
            shout.unsubscribe("activateTestOrgnumber");
        }

    }, []);

    const emailChanged = (_value) => {
        
        let value= _value ===null ? "": _value.trim();
        let isValid = emailValidator.validate(value);
        setEmailIsValid(isValid);
        setPurchaserEmail(value);
        let model = {...props.checkoutModel}
        model.purchaser_email = value;
        props.setCheckoutModel(model);
    }

    const validateOrgNumber = (setOrgNo) => {
        const trimmedOrgNr = setOrgNo.trim();
        let result = isOrganizationNumberValid(trimmedOrgNr);
        setOrganizationNumber(trimmedOrgNr);
        setOrgNrIsValid(result);
    }

    const onSubmitOrgNo = (event, organizationNumber) => {
        //call api from here, then update parent if necessary
        props.setHasClickedOnNext(true);
        event.preventDefault();
        if (!orgNrIsValid || !emailIsValid) {
            return;
        }
        setLoadingCustomer(true);

        //TESTING
        function createEmptyAddress() {
            return {
                company_name: "",
                street_address: "",
                postal_code: "",
                city: "",
                country: ""
            };
        }

        //props.setCustomer(customerMock_rejected);
        putCustomer(props.checkoutModel.id, organizationNumber).then(res => {
            if (res.billing_address === null) {
                res.billing_address = createEmptyAddress();
            }
            if (res.delivery_address === null) {
                res.delivery_address = createEmptyAddress();
            }
            props.setCustomer(res);
            setCustomerNotFoundError(false);
            setLoadingCustomer(false);
        }).catch(err => {
            setCustomerNotFoundError(true);
            setLoadingCustomer(false);
            console.log("Error when putting customer")
        })
    }
    return (
        <div id="organization-number-lookup-div">
            <h3>Dina uppgifter</h3>
            <form id="organization-number-form" onSubmit={(event) => onSubmitOrgNo(event, GetIdentityNumberCleanedAs10Digits(organizationNumber))}>
                <div className="col-container borderTopLeft">
                    <div className="col two full_line">
                        <Input label="Din e-post"
                               id="purchaserEmailInput" placeholder="E-post" name="purchaser-email"
                               type="text"
                               errorMessage={!emailIsValid ? "Ogiltig e-postadress" : null}
                               className={!emailIsValid ? `invalid ${!props.hasClickedOnNext ? 'hideError' : ''}` : 'is-valid'}
                               onChange={(e) => emailChanged(e.target.value)}
                               value={purchaserEmail || ''}/>
                    </div>
                    <div className="col two full_line">
                        <Input label="Organisationsnummer"
                               id="organizationNumberInput" placeholder="Organisationsnummer"
                               name="organizationNumberInput"
                               type="text"
                               errorMessage={!orgNrIsValid ? 'Ange ett giltigt organisationsnummer' : null}
                               className={!orgNrIsValid ? `invalid ${!props.hasClickedOnNext ? 'hideError' : ''}` : 'is-valid'}
                               onChange={(e) => validateOrgNumber(e.target.value)}
                               value={organizationNumber || ''}/>
                    </div>
                </div>
                <div className="col-container">
                    <div className="col">
                        <p className="creditcheck-disclaimer">Vi genomför en kreditupplysning för att kunna presentera avtalsvillkoren för ert företag</p>
                        {customerNotFoundError && 
                            <p className="customer-organisation-error">Vi kunde tyvärr inte hitta en organisation med det organisationsnumret du angav.</p>}
                    </div>
                    <div className="col button-col">
                        <button data-testid="orgNoNextButton"
                            type="submit"
                            id="organization-number-button"
                            disabled={loadingCustomer}
                            className={loadingCustomer ? "button-loading-state" : ""}>
                            {
                                loadingCustomer ?
                                    <div className="col-container">
                                        <div className="col next_text">Fortsätt</div>
                                        <div className="col">
                                            <Spinner isButtonSpinner={true}/>
                                        </div>
                                    </div> :
                                    <div>
                                        Fortsätt
                                    </div>
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
};
export default OrganizationNumberLookup;



