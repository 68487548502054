export class Shout {
    static instance = undefined;
    subscribers = [];
    constructor() {
        if (!!Shout.instance) {
            return Shout.instance;
        }

        Shout.instance = this;
        return this;
    }

    subscribe = (message,callback) =>{
        this.subscribers.push({ message: message, callback: callback });
    };
    shoutOut= (message, value) =>{
        this.subscribers.filter(s => s.message === message).forEach(
            s => s.callback(value)
        );
    }
    unsubscribe= (message) =>{
        this.subscribers.forEach((v,i)=>{
            if(v.message === message){
                this.subscribers.splice(i,1);
            }
        });
    }
}