import React, {useEffect, useState} from 'react';
import '../../css/columns.scss';
import './style.scss';
import {isNonEmptyNormalText, isPostalCode, validateAndUpdateState, validateName, validatePhone} from "../../services/validation";
import {isObjectsEqual, makePropertyValueArray} from "../../services/utils";
// import AddressItem from './AddressItem';
import Recipient from './Recipient';
import Input from '../Input';
import emailValidator from "email-validator";
import AddressItem from './AddressItem';

const CustomerAddress = (props) => {
    const [validation, setValidation] = useState({
        purchaser_email: {validator: emailValidator.validate, isValid: true},
    });

    const [recipientValidation, setRecipientValidation] = useState({
        recipient_name: {validator: validateName, isValid: false},
        recipient_phone: {validator: validatePhone, isValid: false},
    });
    const [billingAddressValidation, setBillingAddressValidation] = useState({
        street_address: {validator: isNonEmptyNormalText, isValid: false},
        postal_code: {validator: isPostalCode, isValid: false},
        city: {validator: isNonEmptyNormalText, isValid: false},
        company_name: {validator: isNonEmptyNormalText, isValid: false}
    });
    const [deliveryAddressValidation, setDeliveryAddressValidation] = useState({
        street_address: {validator: isNonEmptyNormalText, isValid: false},
        postal_code: {validator: isPostalCode, isValid: false},
        city: {validator: isNonEmptyNormalText, isValid: false},
        company_name: {validator: isNonEmptyNormalText, isValid: false}
    });
    const [useBillingAddress, setUseBillingAddress] = useState(false);


    useEffect(() => {
        validateAndUpdateState(setValidation, validation, "purchaser_email", props.checkoutModel.purchaser_email);

        ["street_address", "postal_code", "city", "company_name"].forEach(x => {
            validateAndUpdateState(setBillingAddressValidation, billingAddressValidation, x, props.customer.billing_address[x]);
            validateAndUpdateState(setDeliveryAddressValidation, deliveryAddressValidation, x, props.customer.delivery_address[x]);
        });

        validateAndUpdateState(setRecipientValidation, recipientValidation, "recipient_name", props.checkoutModel.recipient_name);
        validateAndUpdateState(setRecipientValidation, recipientValidation, "recipient_phone", props.checkoutModel.recipient_phone);

        updateCustomerIsValid();

        //diff delivery/billing address

    }, [props.checkoutModel, props.customer]);

    useEffect(() => {
        if (!isObjectsEqual(props.customer.delivery_address, props.customer.billing_address)) {
            setUseBillingAddress(true);
            props.setUseOtherBillingAddress(true);
        }

    }, [])

    const updateCustomerIsValid = () => {
        let localValidation = makePropertyValueArray(validation).every(x => x.value.isValid);
        let _deliveryAddressIsValid = makePropertyValueArray(deliveryAddressValidation).every(x => x.value.isValid === true);
        let _billingAddressIsValid = makePropertyValueArray(billingAddressValidation).every(x => x.value.isValid === true);
        if (!useBillingAddress) {
            _billingAddressIsValid = true;
        }

        let _recipientIsValid = makePropertyValueArray(recipientValidation).every(x => x.value.isValid === true);
        let isAllValid = localValidation && _deliveryAddressIsValid && _billingAddressIsValid && _recipientIsValid;
        if(!isAllValid){
            console.log("NOW", _deliveryAddressIsValid);
            debugger;
        }
        props.setCustomerIsValid(localValidation && _deliveryAddressIsValid && _billingAddressIsValid && _recipientIsValid);
    }

    const onEmailAdressChanged = (value) => {
        validateAndUpdateState(setValidation, validation, "purchaser_email", value)
        updateCustomerIsValid();
        updatePropCheckoutModel("purchaser_email", value);
    }

    const updatePropCheckoutModel = (propName, propValue) => {
        let c = {...props.checkoutModel};
        c[propName] = propValue;
        props.setCheckoutModel(c);
    }
    
    const onUseOtherBillingAddressChange = (e) => {
        let newUseBillingAddress = !useBillingAddress;
        if (newUseBillingAddress === false) {
            //man har kryssat ur
            //Sätt billingaddress till deliveryAddress 
            let c = {...props.customer};
            c.billing_address = JSON.parse(JSON.stringify(c.delivery_address)); //copy 
            props.setCustomer(c);
        }
        setUseBillingAddress(newUseBillingAddress);
        props.setUseOtherBillingAddress(newUseBillingAddress);
    }

    const onRecipientValidationUpdate = (_validation) => {
        setRecipientValidation(_validation);
    }

    if (props.customer === null || props.checkoutModel === undefined) {
        return (
            <React.Fragment/>
        )
    }

    return (
        <div id="customer-address-container">
            <h2>{props.customer.delivery_address.company_name}</h2>
            <div className="delivery-address-container">
                <h4>Leveransadress</h4>
            </div>
            <div className="col-container">
                <div className="col two full_line">
                    <Input
                        errorMessage={validation.purchaser_email.isValid === false ? 'Fyll i giltig epost' : ''}
                        label="E-post"
                        disabled={props.disabled}
                        onChange={(e) => onEmailAdressChanged(e.target.value)}
                        placeholder="E-post"
                        className={validation.purchaser_email.isValid ? 'is-valid' : 'invalid'}
                        value={props.checkoutModel.purchaser_email}/>
                </div>
                <div className="col two full_line">
                    <Input className="disabled" readOnly={true} label="Organisationsnummer" value={props.customer.organization_number}/>
                </div>
            </div>
            <div>
                <AddressItem
                    addressObjectName="delivery_address"
                    setCustomer={props.setCustomer}
                    customer={props.customer}
                    disabled={props.disabled}
                    validation={deliveryAddressValidation}
                    setValidation={setDeliveryAddressValidation}
                />
            </div>
            {
                props.checkoutModel !== undefined &&
                <Recipient
                    setCheckoutModel={props.setCheckoutModel}
                    checkoutModel={props.checkoutModel}
                    validation={recipientValidation}
                    children={props.children}
                    disabled={props.disabled}
                    setValidation={onRecipientValidationUpdate}
                />
            }
            <div>
                <input
                    type="checkbox"
                    id="useOtherBilling"
                    disabled={props.disabled}
                    onChange={() => onUseOtherBillingAddressChange()}
                    name="useOtherBillingAddress"
                    checked={useBillingAddress}/>
                <label htmlFor="useOtherBilling">Använd annan faktureringadress</label>
            </div>
            {
                useBillingAddress &&
                <div id="billingAddressContainer">
                    <div className="delivery-address-container">
                        <h4>Faktureringsadress</h4>
                    </div>
                    <div>
                        <AddressItem
                            addressObjectName="billing_address"
                            customer={props.customer}
                            validation={billingAddressValidation}
                            setValidation={setBillingAddressValidation}
                            setCustomer={props.setCustomer}
                            disabled={props.disabled}

                        />
                    </div>
                </div>
            }
        </div>
    );
}
export default CustomerAddress;