import {getMockData} from './mockData';
export const getMockResponse = (path, timeout) => {
    let _timeout = timeout || 300;
    const getDataAsPromise = (mockDataObject)=>{
      return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(
                getMockData(mockDataObject)
            );
        }, _timeout);
      });
    };

    return getDataAsPromise(path);
}




