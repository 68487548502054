/* @flow */

import React from "react";
import "./style.scss";
import format from "../../services/format";
import {getMonthlyCost} from "./calculations";
// type Props = {
//   style?: any,
//   id?: string,
//   className?: string,
//   price: number,
//   currency: string,
//   paymentInterval: string,
//   financingTime: string,
//   residualValue?: number,
//   selected?: boolean,
//   onClick?: () => void
// };

export default function FinancingBox(props) {
  const {
    id,
    includeInsurance,
    option,
    currency,
    paymentInterval,
    financingTime,
    selected,
    onClick
  } = props;

  let residualValue = Math.round(option.residual_value);
  


  return (
    <div id={id} onClick={onClick} className="col-float flex1">
      <div className={`financing-box ${selected ? 'selected':''}`}>
      <div className="financing-time">
          {option.contract_length} månader
      </div>
      <div className="financing-price">
          {`${format.formatThousandDelimiter(getMonthlyCost(option, includeInsurance))} kr/mån`} 
      </div>
      {
        (residualValue && residualValue > 0) ?
        <div className="financing-residual-value">
            {`Restvärde`} <span className="no-wrap">{`${format.formatThousandDelimiter(residualValue)} kr`}</span>
        </div> :
        null
      }
      </div>
    </div>
  );
}
