import React from 'react';
import "../css/bannerStyle.scss";
import "./style.scss";
import {Shout} from '../../services/shout';
import redCornerIcon from "../../assets/red-corner.svg";
import {AppState} from '../../services/appState';

const TestModeBanner = (props) => {    
    let shout = new Shout();
    let appState = new AppState();
    const handleClick=()=>{
       // props.onSetTestModeOrgNo();
       const testModeOrgNo = "6806249022";
       shout.shoutOut("activateTestOrgnumber", {testOrgNumber: testModeOrgNo});
       appState.set("testMode", true);
    }
    return (
        props.testMode &&
            <div id="testmode-container" className="banner_container">
                <h4 className="header">Testmode is enabled</h4>
                <div className="content"> 
                    <h4 className="sub-title">
                        Test company
                    </h4>
                    <p className="info-text">
                        To complete the checkout, use our test company with organisation number 6806249022.
                    </p>
                    <button
                        onClick={()=>handleClick()}
                        id="checkout-testmode-autofill-organization-number"
                        >
                     Use test company
                         
                    </button>
                    <div className="clear-both"></div>
                </div>
            </div>
    )
};
export default TestModeBanner;



