import React from "react";


export default function InfoTexts(props) {
    return (
        <React.Fragment>
            {(props.infoTexts || []).map((info, index) =>
                <div key={index} className="info-text-container">
                    <h4>
                        {info.header}
                    </h4>
                    <p>
                        {info.body}
                    </p>
                </div>
            )}
        </React.Fragment>
    );
}
