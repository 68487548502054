import React, {useEffect, useState} from 'react';
import './style.scss';
import {cleanString} from "../../services/utils"
import Input from '../Input';
import {validateAndUpdateState} from "../../services/validation";
import {Shout} from '../../services/shout';

const Recipient = (props) => {
    //onupdated
    useEffect(() => {
        validateAndUpdateState(props.setValidation,props.validation,"recipient_name",props.checkoutModel.recipient_name);
        validateAndUpdateState(props.setValidation,props.validation,"recipient_phone",props.checkoutModel.recipient_phone);
    }, [props]);
    useEffect(()=>{
        shout.subscribe("goToSigningButtonOneClicked",(data)=>{
            setGoToSigningButtonOneClicked(true);
        });
        return ()=>{
          shout.unsubscribe("goToSigningButtonOneClicked");
        } 
  
      },[])

      const [goToSigningButtonOneClicked, setGoToSigningButtonOneClicked] = useState(false);
      let shout = new Shout();

    const updatePropCheckoutModel = (propName, propValue) =>{
        let c = {...props.checkoutModel};
        c[propName] = propValue;
        props.setCheckoutModel(c);
    }


    const recipientNameChanged = (e) => {
        let inputValue = cleanString(e.target.value);
        validateAndUpdateState(props.setValidation,props.validation,"recipient_name",inputValue);
        updatePropCheckoutModel("recipient_name",inputValue);

    };

    const recipientPhoneChanged = (e) => {
        let inputValue =e.target.value;
        validateAndUpdateState(props.setValidation,props.validation,"recipient_name",inputValue);
        updatePropCheckoutModel("recipient_phone",inputValue);
    };

    return (
        <div id="billing-details" className="billing-details col-container">
            <div className="col two full_line">
                <Input errorMessage={props.validation.recipient_name.isValid === false ?  'Fyll i giltigt namn': ''} label="Leveransmottagare namn"
                    id="recipient-name" placeholder="Leveransmottagare namn" name="recipient-name"
                    type="text"
                    disabled={props.disabled}
                    className={props.validation.recipient_name.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked?'hideError':''}`}
                    onChange={(e) => recipientNameChanged(e)}
                    value={props.checkoutModel.recipient_name || ''}/>
            </div>
            <div className="col two full_line">
                <Input errorMessage={props.validation.recipient_phone.isValid === false ? 'Fyll i giltigt telefonnummer': ''} label="Leveransmottagare telefonnummer"
                    id="recipient-phone" placeholder="Leveransmottagare telefonnummer" name="recipient-phone"
                    type="text"
                    disabled={props.disabled}
                    className={props.validation.recipient_phone.isValid ? 'is-valid' : `invalid ${!goToSigningButtonOneClicked?'hideError':''}`}
                    onChange={(e) => recipientPhoneChanged(e)}
                    value={props.checkoutModel.recipient_phone || ''}/>
            </div>
            {
                props.children &&
            <div className="col two full_line">
                {
                    props.children
                }
                </div>
        }   
        </div>
    );
};
export default Recipient;
