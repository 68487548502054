export const getCreateAgreementBody = (customer, checkoutModel, selectedFinancingOption, useOtherBillingAddress, includeInsurance) => {
    let deliveryAddress = {
        company_name: customer.delivery_address.company_name,
        street_address: customer.delivery_address.street_address,
        postal_code: customer.delivery_address.postal_code.replace(/\s/, ''),
        city: customer.delivery_address.city
    };

    return {
        checkout_id: checkoutModel.id,
        contract_length: selectedFinancingOption,
        delivery_address: deliveryAddress,
        billing_address: useOtherBillingAddress ? {
            company_name: customer.billing_address.company_name,
            street_address: customer.billing_address.street_address,
            postal_code: customer.billing_address.postal_code.replace(/\s/, ''),
            city: customer.billing_address.city
        } : deliveryAddress,
        recipient_name: checkoutModel.recipient_name,
        recipient_phone: checkoutModel.recipient_phone,
        purchaser_email: checkoutModel.purchaser_email,
        include_insurance: includeInsurance
    }
}

export const createPutAgreementBody = (selectedSignatories, signatoryMessage, personalNumber) => {
    const mapSelectedSignatories = (signatories) => {
        return signatories.filter(s => s.notify).map(x => {
            return {
                name: x.name,
                email: x.email,
                phone: x.phone.length === 0 ? 'xxx' : x.phone,
                personal_number: x.personalNumber
            }
        });
    }

    return {
        general_terms_confirmed: true,
        requested_signatories: selectedSignatories ? mapSelectedSignatories(selectedSignatories) : [],
        personal_number: personalNumber,
        signatory_message: signatoryMessage
        //TODO: Save personal identity number and if it should sign?
    };

} 