import React, { useEffect, useState } from 'react';
import { getAgreement } from "../../services/api";
import './style.scss';
import '../../css/columns.scss';

import ErrorView from '../../components/ErrorView';
import AdditionalSignatories from './AdditionalSignatories';
import CompanyOwnership from './CompanyOwnerShip';
//import Cookies from 'js-cookie'

const AgreementScene = (props) => {
    const [hasError, setHasError] = useState(false);
    const [agreementModel, setAgreementModel] = useState(null);
    useEffect(() => {
        let agreementId = props.agreementId;

        if (!agreementId) {
            console.log("missing querystring:id");
            setHasError(true);
            return;
        }
        getAgreement(agreementId).then(res=>{
            setAgreementModel(res);
        })
        .catch((err)=>{
            setHasError(true);
        })
 
    }, []);

   
    if (hasError) {
        return (<ErrorView />)
    }

    return ( 
        <div className="agreement-container">
            <AdditionalSignatories 
                setSignatoryMessage={props.setSignatoryMessage}
                availableSignatories={props.validateSignatoryResult.available_signatories}
                additionalRequiredSignatories={props.validateSignatoryResult.number_of_additional_required_signatories}
                setSignatoryIsValid = {props.setSignatoryIsValid}
                setSelectedSignatories = {props.setSelectedSignatories}
                />
                <CompanyOwnership agreement={agreementModel} signatoryIsValid={props.signatoryIsValid}/>
        </div>
    );
}
export default AgreementScene;