/* @flow */

import React from "react";

export default function DisableEnableWrapper(props) {
  
    return (
        <React.Fragment>
        {
            <div id="disabledWrapperContainer">
                <div id="content">
                    {
                        props.children
                    }

                </div>
                <div className={props.disabled ? "active": ""}  id="overlay" ></div>
            </div>        
        }
        </React.Fragment>
    );
}
