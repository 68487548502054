export default {
    washPersonalIdentityNumber: personalIdentityNumber => {
      //Remove the - character
      if (personalIdentityNumber.indexOf("-") > -1) {
        personalIdentityNumber = personalIdentityNumber.replace("-", "");
      }
  
      return personalIdentityNumber;
    }
}
  