export const validateAndUpdateState = (stateSetter, validationState, fieldName, value) => {
    let isValid = validationState[fieldName].validator(value);
    if (isValid !== validationState[fieldName].isValid) { //only update if diff from previous value
        let v = {...validationState};
        v[fieldName].isValid = isValid;
        stateSetter(v);
        return true;
    }
    return false;
}


export const isNormalText = (val) => {
    return val.trim().length < 155;
}
export const isNonEmptyNormalText = (val) => {
    if (val === null) {
        return false;
    }
    val = val.trim();
    return val.length > 0 && val.length < 155;
}

export const isNotEmpty = (val) => {
    if (val === null) {
        return false;
    }
    val = val.trim();
    return val.length > 0;
}

export const isPostalCode = (val) => {
    if (val === null) {
        return false;
    }
    val = val.trim();
    return val.match(/^[0-9]{3} ?[0-9]{2}$/) !== null;
}

export const validateName = (name) => {
    if (name && name.trim()) {
        return true;
    } else {
        return false;
    }
}

export const isValidTextAreaText = (input) => {
    if (!input) {
      return false;
    }
    if (input.trim().length < 1  || input.length > 500) {
      return false;
    }
    return true;
  }


export const validatePhone = (phone) => {
    return phone && !!phone.replace(/[ \-+]/gi, '').match('^[0-9 -+]{8,15}$');
}

export const validateNonMandatoryPhone = (phone) => {
    let isEmpty = phone.length === 0;

    return isEmpty || (!!phone.replace(/[ \-+]/gi, '').match('^[0-9 -+]{8,15}$'));
}


export const isOrganizationNumberValid = (input) => {
    return !!(CheckFormatOrganization(input) && CheckLuhnAlgorithm(input));
}

export const isPersonNumberValid = (input) => {
    if (!input || !input.match(/^(\d{4})(\d{2})(\d{2})-?(\d{4})$/)) {
        return false;
    }

    // Get year, month and day
    const year = RegExp.$1,
        month = RegExp.$2 - 1,
        day = RegExp.$3;
    // Check that year month and day are possible numbers
    if (month < 0 || month > 12 || day < 1 || day > 31) {
        return false;
    }

    // Check valid date
    // FIXME: Check for potential edge-cases with 2-digit years(new date will interpret these as 19xx)
    let d = new Date(year, month, day)
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) {
        return false;
    }

    return CheckLuhnAlgorithm(input);
}

export function CleanIdentityNumber(input) {
    // Clean input from - (dash)
    input = input.replace("-", "");

   return input;
}

export function GetIdentityNumberAs10Digits(input) {
    // Trim length if in format YYYYMMDDXXXX => YYMMDDXXXX
    if (input.length === 12) {
        input = input.substring(2);
    }
    return input;
}

export function GetIdentityNumberCleanedAs10Digits(input) {
    input = CleanIdentityNumber(input);
    input = GetIdentityNumberAs10Digits(input);
    return input;
}

function CheckFormatOrganization(input) {
    // Check input is defined and could be orgnr/personalnumber
    if (!input) {
        return false;
    }

    return input.match(/^(\d{6}|\d{8})-?\d{4}$/);
}

function CheckLuhnAlgorithm(input) {
    let cleanIdentityNumber = GetIdentityNumberCleanedAs10Digits(input)
    
    let sum = 0,
        numdigits = cleanIdentityNumber.length,
        parity = numdigits % 2,
        i,
        digit;

    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(cleanIdentityNumber.charAt(i), 10);
        if (i % 2 === parity) {
            digit *= 2;
        }
        if (digit > 9) {
            digit -= 9;
        }
        sum += digit;
    }
    return sum % 10 === 0;
}
