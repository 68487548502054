import React, {useEffect, useState} from 'react';
import {postCreateAgreement, postValidateSignatory, putAgreement, getAgreement} from "../../services/api";
import {createPutAgreementBody, getCreateAgreementBody} from "./agreementFactory";
import './style.scss';
import '../../css/columns.scss';
import OnResize from '../../services/resizeHandler';
import TestModeBanner from '../../components/TestModeBanner';
import {Shout} from '../../services/shout';
import {AppState} from '../../services/appState';
import ErrorView from '../../components/ErrorView';
import OrganizationNumberLookup from "../../components/OrganizationNumberLookup";
import CustomerAddress from "../../components/CustomerAddress";
import SignButton from "../../components/SignButton";
//import Cookies from 'js-cookie'
import TermsScene from '../terms';
import {setCookie} from "../../services/cookies.js";
import {windowRedirect} from "../../services/windowWrapper.js";
import FinancingOptions from '../../components/FinancingOptions';
import RejectedBanner from "../../components/RejectedBanner";
import {loadCheckout} from "./checkoutModelFactory";
import Signatory from '../../components/Signatory';
import Agreement from '../agreement';
import DisclaimerInfo from "../../components/DisclaimerInfo";
import FinancingSummary from '../../components/FinancingOptions/FinancingSummary';
import DisableEnableWrapper from './DisableEnableWrapper';

const CheckoutScene = () => {
    let shout = new Shout();
    let appState = new AppState();

    const [checkoutModel, setCheckoutModel] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerIsValid, setCustomerIsValid] = useState(false);
    const [selectedFinancingOption, setSelectedFinancingOption] = useState(null);
    const [agreementId, setAgreementId] = useState(null);
    const [personNumberIsValid, setPersonNumberIsValid] = useState(false);
    const [signatoryPersonNumber, setSignatoryPersonNumber] = useState(null);
    const [validSignatory, setValidSignatory] = useState(false);
    const [signatoryIsValidated, setSignatoryIsValidated] = useState(false);
    const [validateSignatoryResult, setValidateSignatoryResult] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);
    const [selectedSignatories, setSelectedSignatories] = useState(null);
    const [signatoryMessage, setSignatoryMessage] = useState("");
    const [validRequestedSignatories, setValidRequestedSignatories] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(false);
    const [selectedFinancingContractLengthObject, setSelectedFinancingContractLengthObject] = useState(null);
    const [useOtherBillingAddress, setUseOtherBillingAddress] = useState(false);
    const [hasClickedOnNext, setHasClickedOnNext] = useState(false);

    useEffect(() => {
        appState.set("testMode", false);
        loadCheckout(setCheckoutModel, setHasError);
    }, []);


    const onReset = () => {
        setCustomer(null);
        setCustomerIsValid(false);
        shout.shoutOut("goToSigningButtonOneClicked", false);
        shout.shoutOut("secondGoToSigningButtonOneClicked", false);
        loadCheckout(setCheckoutModel, setHasError);
    }

    const signatoryPersonalNumberChanged = (personalNumber, isValid) => {
        setPersonNumberIsValid(isValid);
        setSignatoryPersonNumber(personalNumber);
    }

    const setCookies = () => {
        setCookie('customer_organization_number', customer.organization_number);
        setCookie('purchaser_email', checkoutModel.purchaser_email);
    }

    const onClick_AB_FirstSignButton = () => {
        shout.shoutOut("goToSigningButtonOneClicked", true);
        if (!personNumberIsValid) {
            return;
        }
        createAgreement();
    }
    const onSoleCompanySendToSigningButton = () => {
        shout.shoutOut("goToSigningButtonOneClicked", true);
        createAgreement().then((agreementId) => {
                sendToSigning(agreementId)
            }
        ).catch(err => {
            console.log("error on ridirecting to signing");
            setBusyLoading(false);
            setHasError(true);
        });

    }
    const createAgreement = () => {
        if (!customerIsValid) {
            return;
        }
        setCookies();
        setBusyLoading(true);

        if (agreementId) // Only one agreement for a checkout
        {
            validateSignatory(agreementId);
            setBusyLoading(false);
            return;
        }

        let body = getCreateAgreementBody(customer, checkoutModel, selectedFinancingOption, useOtherBillingAddress, insuranceValue);

        return postCreateAgreement(body).then(agreementRes => {
            setAgreementId(agreementRes.id);
            validateSignatory(agreementRes.id);
            setBusyLoading(false);
            return agreementRes.id;
        }).catch(err => {
            console.log("Error posting createAgreement", err);
            setHasError(true);
            setBusyLoading(false);
        });
    }

    const validateSignatory = (agreementId) => {
        if (customer.require_selected_signatory) { //DONT validate on enskild firma eftersom det inte finns något att validera
            postValidateSignatory(agreementId, signatoryPersonNumber).then(validateSignatoryResult => {
                //svaret ska indikera om du inte är firmatecknare...
                setValidateSignatoryResult(validateSignatoryResult);
                if(validateSignatoryResult.number_of_additional_required_signatories===0){
                    //no more required signatories, 
                    //load agreement to see if no huvudman information is reqiured to show, otherwise just move on to signing, 
                    //to avoid have to click on another button.
                    getAgreement(agreementId).then(a=>{
                        if(a.has_beneficiaries===false){
                            sendToSigning(agreementId);
                        }
                    })
                }
                setValidSignatory(true);
                setSignatoryIsValidated(true);
            }).catch(validateSignatoryError => {
                setValidSignatory(false);
                setSignatoryIsValidated(true);
                console.log("Error when posting validateSignatory");
            })
        }
    }

    const AB_HasWentOnToSelectSigners = () => {
        return agreementId !== null && validSignatory;
    }

    const onSendMultipleSignatoriesToSigning = (agreementIdToUpdate) => {
        shout.shoutOut("secondGoToSigningButtonOneClicked", true);
        if (!validRequestedSignatories) {
            return false;
        }
        sendToSigning(agreementIdToUpdate);
    }

    const sendToSigning = (agreementIdToUpdate) => {
        //if enskild firma - använd orgnumret som personnummer eftersom det är samma. Annars ta det man angett
        let usePersonalNumber = customer.require_selected_signatory ? signatoryPersonNumber : customer.organization_number; //maybe verify that this is the same as agreementModel.billingAdress.orgNo
        const body = createPutAgreementBody(selectedSignatories, signatoryMessage, usePersonalNumber);
        setBusyLoading(true);
        putAgreement(agreementIdToUpdate, body).then(res => {
            windowRedirect(res.signing_url);
        }).catch(err => {
            console.log("Error when putting agreement2");
            setHasError(true);
            setBusyLoading(false);
        })
    }

    if (hasError) {
        return (<ErrorView/>)
    }
    if (checkoutModel === null) {
        return (<div/>)
    }
    if (customer !== null && customer.checkout_status === false) {
        return (
            <React.Fragment>
                <TestModeBanner testMode={checkoutModel.test_mode}/>
                <RejectedBanner onReset={onReset} checkoutModel={checkoutModel} handleCancel={e => alert('not implemented')} companyName={customer.organization_name}/>
            </React.Fragment>
        );
    }

    return (
        <div className="main-container">
            <OnResize/>
            <TestModeBanner testMode={checkoutModel.test_mode}/>
            <DisclaimerInfo/>
            <DisableEnableWrapper disabled={AB_HasWentOnToSelectSigners()}>
                <div>
                    <FinancingOptions
                        setSelectedFinancingOption={setSelectedFinancingOption}
                        setSelectedFinancingContractLengthObject={setSelectedFinancingContractLengthObject}
                        setInsuranceValue={setInsuranceValue}
                        disabled={AB_HasWentOnToSelectSigners()}
                        partnerId={checkoutModel.partner_id}
                        checkoutId={checkoutModel.id}/>
                    {
                        customer === null &&
                        <OrganizationNumberLookup
                            setHasClickedOnNext={setHasClickedOnNext}
                            hasClickedOnNext={hasClickedOnNext}
                            setCheckoutModel={setCheckoutModel}
                            checkoutModel={checkoutModel}
                            setCustomer={setCustomer}
                        />
                    }
                    {
                        customer !== null &&
                        <CustomerAddress
                            setUseOtherBillingAddress={setUseOtherBillingAddress}
                            customer={customer}
                            disabled={AB_HasWentOnToSelectSigners()}
                            customerIsValid={customerIsValid}
                            setCustomerIsValid={setCustomerIsValid}
                            setCustomer={setCustomer}
                            setCheckoutModel={setCheckoutModel}
                            checkoutModel={checkoutModel}>
                            {
                                customer.require_selected_signatory ?
                                    <Signatory 
                                    signatoryPersonalNumber={signatoryPersonNumber} 
                                    isValidSignatory={validSignatory}
                                    disabled={AB_HasWentOnToSelectSigners()}
                                     signatoryIsValidated={signatoryIsValidated}
                                               signatoryPersonalNumberChanged={signatoryPersonalNumberChanged}/>
                                    : null
                            }
                        </CustomerAddress>
                    }
                    {
                        // Hantera Enskild firma
                        customer && !customer.require_selected_signatory ?
                            <SignButton
                                onClickHandler={onSoleCompanySendToSigningButton}
                                loading={busyLoading}
                                disabled={busyLoading}/> :
                            (customer && !AB_HasWentOnToSelectSigners()) ?
                                <SignButton
                                    onClickHandler={onClick_AB_FirstSignButton}
                                    loading={busyLoading}
                                    disabled={busyLoading}/> :
                                <div className="heightSpacer">&#160;</div>
                    }

                </div>
            </DisableEnableWrapper>
            {
                (AB_HasWentOnToSelectSigners()) ?
                    <React.Fragment>
                        {
                            customer !== null && customer.require_selected_signatory ?
                                <Agreement
                                    agreementId={agreementId}
                                    setSignatoryIsValid={setValidRequestedSignatories}
                                    setSignatoryMessage={setSignatoryMessage}
                                    setSelectedSignatories={setSelectedSignatories}
                                    signatoryIsValid = {validRequestedSignatories}
                                    validateSignatoryResult={validateSignatoryResult}/>
                                : null
                        }
                        <SignButton
                            onClickHandler={() => onSendMultipleSignatoriesToSigning(agreementId)}
                            loading={busyLoading}
                            disabled={busyLoading}/>
                    </React.Fragment> :
                    null
            }
            <FinancingSummary
                insuranceValue={insuranceValue}
                selectedOption={selectedFinancingContractLengthObject}/>

            <TermsScene
                partnerId={checkoutModel.partner_id} insuranceValue={insuranceValue}/>

        </div>
    );
};
export default CheckoutScene;