import React, {useState} from "react";
import "./style.scss";
import format from "../../services/format";
import {getMonthlyCost,getTotalCost} from "./calculations";


export default function FinancingSummary(props) {
    const [showTotalCost,setShowTotalCost] = useState(false);
    let option = props.selectedOption;
    if(option===undefined || option === null ){ 
        return (<div></div>);
    }

    const toggleShowTotalCost = ()=>{
        let newVal = !showTotalCost;
        setShowTotalCost(newVal);
    }

  return (
    <div className="financing-selection-details">  
        <div className="centered-box">
            <span className="contract-length">
                {option.contract_length} månader
            </span>
            <span className="monthly-cost">
                {`${format.formatThousandDelimiter(getMonthlyCost(option, props.insuranceValue))} kr/mån`}
            </span>
            {
                props.insuranceValue ?
                <span className="contract-length">
                    inklusive försäkring
                </span>                    
                :null
            }

{
                option.residual_value === 0 && Number(option.start_fee) > 0 ? 
                <span class="rental_upplaggningsavgift">
                    Uppläggningsavgift
                    {` ${format.formatThousandDelimiter(option.start_fee)} kr`}
                    <div className="exvat_text"><small>Alla belopp är exkl. moms</small></div>
                </span> : null
            }

            {

            option.residual_value > 0 ?
            <React.Fragment>
            <button onClick={toggleShowTotalCost}>Visa Totalkostnad</button>
            <div className={`financing-totals ${!showTotalCost? 'hide':''}`}>
                <div className="box">
                    
                    {
                     Number(option.start_fee) > 0 ?
                    <div>
                        <span>Uppläggningsavgift</span>
                        <span>
                            {`${format.formatThousandDelimiter(option.start_fee)} kr`}
                        </span>
                    </div> :null
                    }
                    <div>
                        <span data-testid="financing-summary-total-cost">
                           
                           {`${option.contract_length} månader x ${format.formatThousandDelimiter(getMonthlyCost(option, props.insuranceValue))} kr/mån`}
                        </span>
                        <span data-testid="financing-summary-total-cost-value">
                            {`${format.formatThousandDelimiter(getTotalCost(option, props.insuranceValue))} kr`}
                        </span>
                    </div>
                    {
                        option.residual_value > 0 &&
                    <div>
                        <span>Restvärde efter leasingtid</span>
                        <span>
                            {`${format.formatThousandDelimiter(Math.round(option.residual_value))} kr`}
                        </span>
                    </div>
                    }
                </div>
                <div className="exvat_text"><small>Alla belopp är exkl. moms</small></div>
            </div>
            </React.Fragment> :null
}
        </div>                                      
    </div>
    );
}
