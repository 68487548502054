import React, { useState, useEffect } from 'react';
import { getFinancingOptions } from "../../services/api";
import FinancingBox from "./FinancingBox";
import "./style.scss";
import "../../css/columns.scss";
import InfoTexts from './InfoTexts';
import ReadOnlyFinancingBox from './ReadOnlyFinancingBox';

const FinancingOptions = (props) => {
    const [financingOptionsModel, setFinancingOptionsModel] = useState(null);
    const [showSpinner, setShowspinner] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [insuranceValue, setInsuranceValue] = useState(false);
    const [insuranceSelectable, setInsuranceSelectable] = useState(false);
    useEffect(() => {
        setShowspinner(true)
        getFinancingOptions(props.checkoutId, props.partnerId).then(res => {
            setFinancingOptionsModel(res);
            setInsuranceSelectable(res.insurance_selectable);
            setInsuranceValue(res.insurance_default);
            props.setInsuranceValue(res.insurance_default);
            setSelectedOption(res.default_contract_length);
            props.setSelectedFinancingOption(res.default_contract_length);
            props.setSelectedFinancingContractLengthObject(res.contract_lengths.filter(x=>x.contract_length === res.default_contract_length)[0]);
            setShowspinner(false);
        }).catch(err => {
            console.log("error");
            setShowspinner(false);
        })


    }, []);

    const updateFinancingOption = (selectedOptionValue) => {
        setSelectedOption(selectedOptionValue);
        props.setSelectedFinancingOption(selectedOptionValue);
        props.setSelectedFinancingContractLengthObject(financingOptionsModel.contract_lengths.filter(x=>x.contract_length === selectedOptionValue)[0]);
        console.log("selected financing option");
    }

    const toggleInsurance = () => {
        let currentVal = insuranceValue;
        setInsuranceValue(!currentVal);
        props.setInsuranceValue(!currentVal);
    }


    if(financingOptionsModel === null){        
        return (<div></div>);
    }


  
    return (

        <React.Fragment>
        <div className="financing-options">
            {
                financingOptionsModel.contract_lengths.length === 1?
            <h4>Månadskostnad</h4>
            :<h4>Välj månadskostnad</h4>
            }
            {
            showSpinner ?
                <div className="financing-options__loading" /> :
                <div className="col-container stretch">
                    {
                        financingOptionsModel.contract_lengths.length === 1 ?
                        <ReadOnlyFinancingBox
                        id={`financing-option-${1}`}
                        includeInsurance={insuranceValue}
                        option={financingOptionsModel.contract_lengths[0]}
                        />
                        :
                        

                    (financingOptionsModel.contract_lengths || [])
                        .map((option, index) =>
                            props.disabled===false?
                            <FinancingBox
                                id={`financing-option-${index}`}
                                key={index}
                                includeInsurance={insuranceValue}
                                option={option}
                                selected={selectedOption === option.contract_length}
                                onClick={() => updateFinancingOption(option.contract_length)}
                            />:

                            <ReadOnlyFinancingBox
                            id={`financing-option-${index}`}
                            key={index}
                            includeInsurance={insuranceValue}
                            option={financingOptionsModel.contract_lengths[0]}
                            />
    
                        )}
                    <div className="financing-options__options-clear"></div>
                </div>
            }
            {
                insuranceSelectable ?
                    <div className="insurance">
                        <input type="checkbox" disabled={props.disabled} onChange={() => toggleInsurance()} 
                        id="insurance_cb" name="insurance_cb" 
                        className="financing-options__insurance-checkbox" checked={insuranceValue} />
                        <label htmlFor="insurance_cb">Inkludera försäkring</label>
                        <a className="readMore" target="_blank" href="https://www.wasakredit.se/foretag/erbjud-finansiering/foretagskunder/forsakring/">Läs mer</a>
                    </div>
                    : null
            }
            <InfoTexts infoTexts={insuranceValue ? financingOptionsModel.info_texts_including_insurance : financingOptionsModel.info_texts} />
        </div>
    </React.Fragment>


    )
};
export default FinancingOptions;



