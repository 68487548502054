import React, {useEffect, useState} from 'react';
import './style.scss';
import {CleanIdentityNumber, isPersonNumberValid} from "../../services/validation";
import {Shout} from '../../services/shout';

import Input from "../Input";

const Signatory = (props) => {
    const [signatoryPersonNumber, setSignatoryPersonNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [pNumClassName, setpNumClassName] = useState("invalid");
    const [goToSigningButtonOneClicked, setGoToSigningButtonOneClicked] = useState(false);

    useEffect(() => {
        if(props.signatoryPersonalNumber) {
            changePersonalNumber(props.signatoryPersonalNumber);
        }
        shout.subscribe("goToSigningButtonOneClicked",(data)=>{
            setGoToSigningButtonOneClicked(true);
        });
        return ()=>{
          shout.unsubscribe("goToSigningButtonOneClicked");
        } 

    }, []);
    let shout = new Shout();

 
    const changePersonalNumber = (pnr) => {
        if (pnr !== null) {
            pnr= pnr.trim();
            let result = isPersonNumberValid(pnr);
            setpNumClassName(result ? "is-valid" : 'invalid');
            setSignatoryPersonNumber(pnr);
            if (!result) {
                setErrorMessage("Ange ett giltigt personnummer med 12 siffror på formen YYYYMMDD-XXXX");
            } else {
                setErrorMessage(null);
            }
            props.signatoryPersonalNumberChanged(CleanIdentityNumber(pnr), result);
        }
    }

    return (
        <div className="signatory-container">
            <div id="signatoryPersonNumberContainer">
                <div id="agreement-signee">
                    <Input
                        id="personal-number"
                        className={`${pNumClassName}${!goToSigningButtonOneClicked?' hideError':''}`}
                        label="Ditt personnummer (12 siffror)"
                        placeholder="Ditt personnummer (12 siffror)"
                        type="text"
                        disabled={props.disabled}
                        errorMessage={errorMessage}
                        onChange={e => changePersonalNumber(e.target.value)}
                        value={signatoryPersonNumber}
                    />
                    {
                        !props.signatoryIsValidated || props.isValidSignatory ?
                            <p id="sign-disclaimer">
                                {`Ditt personnummer används för att se om du har rätt att teckna och signera avtalet via BankID.`}
                            </p>
                            :
                            <div>
                            <p className="agreement-signee__personal-number-error-message">
                                Enligt uppgifterna hos Bolagsverket så är inte du firmatecknare och du kan därför inte signera avtalet.
                                 Vänligen be en firmatecknare signera avtalet.
                            </p>
                        </div>
                    }     
                </div>
            </div>
        </div>
    )
}

export default Signatory;