import React, {useState,useEffect} from 'react';
import Input from '../../components/Input';
import {Shout} from '../../services/shout';

const AdditionalSignatory = (props) => {
  const [secondGoToSigningButtonOneClicked, setSecondGoToSigningButtonOneClicked] = useState(false);
  useEffect(() => {
    shout.subscribe("secondGoToSigningButtonOneClicked",(data)=>{
      setSecondGoToSigningButtonOneClicked(true);
    });
    return ()=>{
      shout.unsubscribe("secondGoToSigningButtonOneClicked");
    } 

}, []);
    
    let shout = new Shout();

    const GetEmailFieldClassName = () => {
        return GetClassNameForInput(props.signatory.emailIsValid);
    }
    
    const GetPhoneFieldClassName = () => {
        return GetClassNameForInput(props.signatory.phoneIsValid);
    }

    const GetClassNameForInput = (valid) => {
        if(props.signatory.notify){
            return valid
                ? "valid"
                : "invalid"
        }
        if(!props.isRequiredNumberOfSignatoriesSelected){
            return `required ${!secondGoToSigningButtonOneClicked?'hideError':''}`;
        }
        return "editable-address__field"
    }
    
  return (
    <div className="row">
        <div className="signatory-name">
          <span data-hj-suppress >{props.signatory.name}</span>  
        </div>

        <div className="col-container" key={props.index}>
    
          <div className="col two">
            <Input
              id={`signatory-email-${props.index}`}
              className={GetEmailFieldClassName()}
              type="text"
              errorMessage={props.signatory.emailIsValid ? "" : "Fyll i en giltig e-post"}
              value={props.signatory.email}
              placeholder="E-post"
              onChange={e =>
                props.changeEmailOnAdditionalSignatory({
                  index: props.index,
                  value: e.target.value
                })}
            />
          </div>
          
          <div className="col two">
            <Input
              id={`signatory-phone-${props.index}`}
              className={GetPhoneFieldClassName()}
              type="text"
              errorMessage={props.signatory.phoneIsValid ? "" : "Fyll i ett giltigt mobilnummer"}
              value={props.signatory.phone}
              placeholder="Mobilnummer"
              onChange={e =>
                props.changePhoneOnAdditionalSignatory({
                  index: props.index,
                  value: e.target.value
                })}
            />
          </div>
        </div>
    </div>

  );
}
export default AdditionalSignatory;



