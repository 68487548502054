import {getIdFromQueryString} from '../../services/utils';
import {getCheckout} from "../../services/api";
import {AppState} from '../../services/appState';
import {getCookie,getCookieValueIfMissingFromModel} from "../../services/cookies.js";

export const loadCheckout = (setCheckoutCallback, setErrorCallback) =>{
    let appState = new AppState();
    let checkoutId = getIdFromQueryString();
    if (!checkoutId) {
        console.log("missing querystring:id");
        setErrorCallback(true);
    }
    getCheckout(checkoutId).then(checkoutResult => {
        checkoutResult.customer_organization_number = getCookieValueIfMissingFromModel(checkoutResult, "customer_organization_number");
        checkoutResult.purchaser_email = getCookieValueIfMissingFromModel(checkoutResult, "purchaser_email");
        setCheckoutCallback(checkoutResult);
        appState.set("testMode", checkoutResult.test_mode);
    }).catch(err => {
        console.log("Error fetching checkout", err);
        setErrorCallback(true);
    });
        
}
