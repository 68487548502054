
import React, { useEffect, useState } from 'react';
import ErrorView from '../../components/ErrorView';
import { loadCheckout } from "../checkout/checkoutModelFactory";
import {AppState} from '../../services/appState';
import postMessage from "../../services/postMessage";
import signingCheck from "../../assets/signing-check.svg";
import Button from "../../components/Button";
import DisclaimerInfo from "../../components/DisclaimerInfo";

const SigningComplete = (props) => {
    let appState = new AppState();
    const [checkoutModel, setCheckoutModel] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [postedMessage, setPostedMessage] = useState(false);
    useEffect(() => {
        appState.set("testMode", false);
        loadCheckout(setCheckoutModel, setHasError);
    }, [])
   
    if (checkoutModel !== null && !postedMessage) {
        postMessage.checkoutComplete(checkoutModel.order_references);
        postMessage.scrollToTop();
        console.log("posted message...complete");
        setPostedMessage(true);
    }

    if (hasError) {
        return <ErrorView />
    }

    return (
        <div className="signing-complete-container">
            <DisclaimerInfo/>
            <h1 className="signing-complete-header__h1">
                <img className="signing-complete-header__h1__check" src={signingCheck} alt="" /> Signerat!
            </h1>
            <h2 id="thank-you-message" className="signing-complete__header__h2">Tack! Du har nu signerat avtalsvillkoren.</h2>
            <h3 className="signing-complete__content__header">Vad händer nu?</h3>
            <p className="signing-complete__content__whats-next__p">
                När avtalet är godkänt får du en bekräftelse via e-post.
            </p>
            {
                (window && window.self !== window.top) && checkoutModel !== null && checkoutModel.checkout_completed_return_url ?
                    <div className="col-xs-12 col-sm-4">
                        <Button
                            id="proceed-after-signing"
                            onClick={() => postMessage.checkoutRedirect(checkoutModel.order_references)}
                            className="signing-complete__content__whats-next__button"
                            label="Gå vidare" />
                    </div> :
                    null

            }
        </div>
    )
}
export default SigningComplete;



