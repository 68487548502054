import {fetchAuthorizedHandler, putData, postData, fetchAuthorizedHandlerWithoutTestMode, getUseMock} from "./apiHandlers";
import identityHelpers  from "../../services/identityHelpers";
import {getMockResponse} from "./mockResponses";
//const host_url = "http://52.57.201.133:5002"
const prefix = window.location.origin.indexOf("amazonaws")!==-1 ? '':'/api';


export const getCheckout = (checkoutId) => {
  if(getUseMock()){
    return getMockResponse("getCheckout");
  }
  return fetchAuthorizedHandler(`${prefix}/v1/leasingCheckouts/${checkoutId}`);
}

export const getDisclaimers = () => {
  if(getUseMock()){return getMockResponse("getDisclaimers");}
  return fetchAuthorizedHandlerWithoutTestMode(`${prefix}/v1/leasingCheckouts/disclaimers`);
}

export const getFinancingOptions = (checkoutId, partnerId) => {
   if(getUseMock()){return getMockResponse("getFinancingOptions");}
    return fetchAuthorizedHandler(`${prefix}/v1/leasingCheckouts/${checkoutId}/${partnerId}/financingOptions`);
}
export const putCustomer = (checkoutId,organizationNumber) => {
    if(getUseMock()){return getMockResponse("putCustomer");}
    return putData(`${prefix}/v1/leasingCheckouts/${checkoutId}/customer/${organizationNumber}`,{});
}

export const getCheckoutState = (checkoutId) => {
    if(getUseMock()){return getMockResponse("getCheckoutState");}
    return fetchAuthorizedHandler(`${prefix}/v1/leasingCheckouts/${checkoutId}/state`);
}
export const postCreateAgreement = (body) => { //see checkout-frontend for body spec...
    if(getUseMock()){return getMockResponse("postCreateAgreement");}
    return postData(`${prefix}/v1/agreements`, body);
}
export const getAgreement = (agreementId) => {
    if(getUseMock()){return getMockResponse("getAgreement");}

    return fetchAuthorizedHandler(`${prefix}/v1/agreements/${agreementId}`);
}

export const putAgreement = (agreementId, agreementModel) => {
  if(getUseMock()){return getMockResponse("putAgreement");}

  return putData(`${prefix}/v1/agreements/${agreementId}`, agreementModel);
}


export const postValidateSignatory = (agreementId, personNumber) => {
    if(getUseMock()){return getMockResponse("postValidateSignatory");}

    let pnr = identityHelpers.washPersonalIdentityNumber(personNumber);
    
    let body={
        agreement_id: agreementId,
        personal_number: pnr
      };

    return postData(`${prefix}/v1/agreements/${agreementId}/signatories`, body);
}


export const getTerms = async (partner_id, insurance_value) => {
  if(getUseMock()){return getMockResponse("getTerms");}

  return fetchAuthorizedHandlerWithoutTestMode(`${prefix}/v1/terms/${partner_id}/${insurance_value}`);
}

export const getGdprTerms = async () => {
  if(getUseMock()){return getMockResponse("getGdprTerms");}

  return fetchAuthorizedHandlerWithoutTestMode(`${prefix}/v1/gdprTerms`);
}