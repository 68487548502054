import {AppState} from '../../services/appState';
import { GetValueFromQueryString } from '../utils';

export const getUseMock = ()=>{
    return  GetValueFromQueryString("useMock")==="true";
}
  
export const fetchAuthorizedHandler = (url) => {
    let appState = new AppState();
    return new Promise((resolve, reject) => {
        fetch(url, {
            redirect: 'error',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "x-test-mode":appState.get("testMode")
            },

        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            })
    });
}

export const fetchAuthorizedHandlerWithoutTestMode = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            redirect: 'error',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },

        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            })
    });
}

export const putData = (url, data) => {
    return putOrPost(url, data, "PUT");
}
export const postData = (url, data) => {
    return putOrPost(url, data, "POST");
}


const putOrPost = (url, data,  method) => {
    let appState = new AppState();
 
    return new Promise((resolve, reject) => {

        fetch(url, {
            method: method,
            redirect: 'error',
            headers: {
                "Content-Type": "application/json",
                "x-test-mode":appState.get("testMode")
            },
            body: JSON.stringify(data),
        })
            .then(errorHandler)
            .then(response => {
                return response.text();
                //resolve(response.json());
            }).then(data=>{
                resolve(data ? JSON.parse(data) : {})
            })
            .catch(err => {
                reject(err);
            });
    })
}

const errorHandler = (response) => {
    if (response.ok)
        return response;

    if (response.status >= 400 && response.status < 500) {
        return response.json()
            .then(json => {
                console.warn('Bad request : body', json);
                let error = Error(json.description);
                error.developer_message = json.developer_message;
                error.statusCode = response.status;
                throw error;
            })
            .catch(err => {
                console.warn('Bad request no json body:', err.message);
                throw err;
            });
    }

    return response.json()
        .then(json => {
            let error;
            console.error('Internal server error:', json.developer_message ? json.developer_message : json);
            error = Error( `${json.developer_message ?  json.developer_message :''} ${response.statusText}`);
            error.statusCode = response.status;
            throw error;
        })
        .catch(err => {
            err.statusCode = response.status;
            console.log("Could not parse response to json!");
            throw err;
        });
}