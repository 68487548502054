export class AppState {
    static instance = undefined;
    db = {};
    constructor() {
        if (!!AppState.instance) {
            return AppState.instance;
        }

        AppState.instance = this;
        return this;
    }
    get = (propName) =>{
        return this.db[propName];
    };
    set = (propName, value) =>{
        this.db[propName] = value;
    }
    getDb = () => {
        return this.db;
    }
}