
import React, { useEffect, useState } from 'react';
import format from "../../services/format";
import AdditionalSignatory from './AdditionalSignatory';
import emailValidator from "email-validator";
import {validatePhone,isValidTextAreaText} from "../../services/validation";

const AdditionalSignatories = (props) => {
  const [selectableSignatories, setSelectableSignatories] = useState([]);
  const [signatoryMessage, setSignatoryMessage] = useState("");
  const [isRequiredNumberOfSignatoriesSelected, setIsRequiredNumberOfSignatoriesSelected] = useState(false);
  useEffect(() => {
    if (props.availableSignatories === null || props.additionalRequiredSignatories == null) {
      return;
    }
    let mapped = props.availableSignatories.map((s, i) => {
      return {
        notify: (i === 0 && props.availableSignatories.length === 1 && Number(props.additionalRequiredSignatories) === 1) ? true : false,
        name: s.name,
        personalNumber: s.personal_number,
        email: '',
        phone: '',
        emailIsValid: false,
        phoneIsValid: false
      }
    });
    setSelectableSignatories(mapped);
  }, [])

  useEffect(()=>{
    const validateAll = () =>{
      let allPropsValid = selectableSignatories.every(x=>  (x.notify && x.emailIsValid && x.phoneIsValid) || !x.notify);
      let _isRequiredNumberOfSignatoriesSelected = selectableSignatories.filter(x=>x.notify).length >=  props.additionalRequiredSignatories;
      setIsRequiredNumberOfSignatoriesSelected(_isRequiredNumberOfSignatoriesSelected)
      props.setSignatoryIsValid(allPropsValid && _isRequiredNumberOfSignatoriesSelected);
    }
    validateAll();
    //bubble up...
    props.setSignatoryMessage(signatoryMessage);
    props.setSelectedSignatories(selectableSignatories);
    
  },[selectableSignatories, signatoryMessage])

  const signatoryMessageChange = (value) => {
    setSignatoryMessage(value);
  }

  const changeEmailOnAdditionalSignatory = (obj) => {
    //{index,string value}
    var signatories = [...selectableSignatories];
    signatories[obj.index].email = obj.value;
    signatories[obj.index].emailIsValid = emailValidator.validate(obj.value);
    setSelectableSignatories(signatories);
    setValidationOnSignatory(obj.index);
  }
  
  const setValidationOnSignatory = (index) => {
    let signatories = [...selectableSignatories];
    let currentSignatory = signatories[index];
    currentSignatory.notify = ((currentSignatory.email.length > 0) || (currentSignatory.phone.length > 0));
    setSelectableSignatories(signatories);
  }
  
  const changePhoneOnAdditionalSignatory = (obj) => {
    //{index,string value}
      var signatories = [...selectableSignatories];
      signatories[obj.index].phone = obj.value;
      signatories[obj.index].phoneIsValid = validatePhone(obj.value);
      setSelectableSignatories(signatories);
      setValidationOnSignatory(obj.index);
  }
  
  
  if (props.additionalRequiredSignatories === null || props.additionalRequiredSignatories === 0 || props.availableSignatories === null) {
    return (<div data-testid="empty-div" />)
  }

  return (
    <React.Fragment>
      {
        selectableSignatories.length > 0 ?
          <div>
            <div className="agreement-signee-header">
              <h2>Välj ytterligare firmatecknare</h2>
              <span className="agreement-signee__meta-heading">
              Enligt era firmateckningsregler måste 
                <b> {` ${format.numberToText(props.additionalRequiredSignatories)} `}till firmatecknare</b> signera avtalet. 
                {
                  selectableSignatories.length === 1 ?
                    <p data-testid="valj-nedan" >Välj nedan.</p>
                    :
                    <p data-testid="valj-flera-nedan">Välj gärna flera om du vill snabba på processen.</p>
                }
              </span>
            </div>
            {(selectableSignatories || []).map((signatory, index) =>
              <AdditionalSignatory
                key={`additionalSignatories_${index}`}
                signatory={signatory}
                index={index}
                isRequiredNumberOfSignatoriesSelected = {isRequiredNumberOfSignatoriesSelected}
                changePhoneOnAdditionalSignatory = {changePhoneOnAdditionalSignatory}
                changeEmailOnAdditionalSignatory={changeEmailOnAdditionalSignatory}
                numberOfNotifiedSignatories={selectableSignatories.map(s => s.notify).length}
                additionalRequiredSignatories={props.additionalRequiredSignatories} />
            )}
            <div className="row">
              <div className="col-xs-12">
                <div className="agreement-signee__h2">
                  <h2>Meddelande till dina kollegor</h2>
                </div>
                <textarea
                  id="signatory-message"
                  placeholder="Meddelande (Kommentar till andra firmatecknare i bolaget)"
                  className={
                    isValidTextAreaText(signatoryMessage)
                      ? "agreement-signee__custom-textarea is-valid"
                      : "agreement-signee__custom-textarea invalid"
                  }
                  value={signatoryMessage}
                  onChange={e => signatoryMessageChange(e.target.value)}
                />
              </div>
            </div>
          </div>
          : <div data-testid="empty-signatories-list"/>
      }

    </React.Fragment>

  );
}
export default AdditionalSignatories;



